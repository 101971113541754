import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AddressWAVAX, ERC20_ABI, OTC3_ABI } from '../constants';
import { OTC3Order } from 'src/app/global';
import { utils, providers, Contract, Signer, BigNumber, constants, ethers } from 'ethers';

import { NetworkService } from './network.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class OTC3Service {
  private network: any;
  private provider: providers.BaseProvider;

  private otc3: Contract;

  constructor(private storage: StorageService, private networkService: NetworkService) {}

  async initiateOTC3Connection() {
    this.network = await this.networkService.getActiveAvaxNetwork();
    this.provider = providers.getDefaultProvider(this.network.RPC_URL);

    this.otc3 = new Contract(environment.OTC3_ADDRESS, new utils.Interface(OTC3_ABI), this.provider);
  }

  /*
   * @dev: query the ids of current active orders set up by the user on the OTC exchange smart contract.
   * @input: user's wallet address.
   * returns: an array of integer ids.
   **/
  async getUserOrders(userAddr: string) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const ids = await this.otc3.getUserActiveOrderIds(userAddr);
    const idsAsUint256Array = ids.map((id: BigNumber) => id.toString());

    const info = await this.otc3.getActiveOrdersInfo(idsAsUint256Array);
    const orders = [];

    for (let i = 0; i < ids.length; i++) {
      orders.push({
        id: ids[i],
        info: info[i],
      });
    }

    return orders;
  }

  async getMarketOrders(user?: string, _tokenIn?: string, _tokenOut?: string) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const ids = await this.otc3.getMarketActiveOrderIds();
    const idsAsUint256Array = ids.map((id: BigNumber) => id.toString());

    const info = await this.otc3.getActiveOrdersInfo(idsAsUint256Array);

    const orders = [];

    for (let i = 0; i < ids.length; i++) {
      orders.push({
        id: ids[i],
        info: info[i],
      });
    }

    let tokenIn = _tokenIn === AddressWAVAX ? constants.AddressZero : _tokenIn;
    let tokenOut = _tokenOut === AddressWAVAX ? constants.AddressZero : _tokenOut;

    // return orders.filter(elem => elem.info.recipient !== user && elem.info.tokenIn === tokenIn && elem.info.tokenOut === tokenOut);
    if (tokenIn && tokenOut) return orders.filter((elem) => elem.info.recipient !== user && elem.info.tokenIn === tokenIn && elem.info.tokenOut === tokenOut);
    else if (tokenIn) {
      return orders.filter((elem) => elem.info.recipient !== user && elem.info.tokenIn === tokenIn);
    } else if (tokenOut) {
      return orders.filter((elem) => elem.info.recipient !== user && elem.info.tokenOut === tokenOut);
    } else {
      return orders.filter((elem) => elem.info.recipient !== user);
    }
  }

  async placeOrder(wallet: Signer, orderInfo: OTC3Order) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const tokenInContract = new Contract(orderInfo.tokenIn.assetGuid, new utils.Interface(ERC20_ABI), this.provider);
    const tokenOutContract = new Contract(orderInfo.tokenOut.assetGuid, new utils.Interface(ERC20_ABI), this.provider);

    const tokenInAllowance = this._toFloat(
      await tokenInContract.allowance(await wallet.getAddress(), this.otc3.address),
      await tokenInContract.decimals(),
      6
    );

    if (tokenInAllowance <= orderInfo.amountIn) {
      await this._setAllowance(tokenInContract, wallet, this.otc3.address);
    }

    try {
      const tx = await this.otc3
        .connect(wallet)
        .placeOrder(
          orderInfo.tokenIn.assetGuid,
          orderInfo.tokenOut.assetGuid,
          this._toBigInt(orderInfo.amountIn, await tokenInContract.decimals()),
          this._toBigInt(
            orderInfo.amountOut,
            orderInfo.tokenOut.assetGuid !== AddressWAVAX ? await tokenOutContract.decimals() : 18
          )
        );
      await tx.wait();
    } catch (e) {
      console.log('Error placing order in OTC3', e);
      throw e;
    }
  }

  // @dev: only applicable when user wants to sell AVAX for token.
  async placeAVAXForTokenOrder(wallet: Signer, orderInfo: OTC3Order) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const tokenOutContract = new Contract(orderInfo.tokenOut.assetGuid, new utils.Interface(ERC20_ABI), this.provider);

    try {
      await this.otc3
        .connect(wallet)
        .placeOrderAVAX(orderInfo.tokenOut.assetGuid, this._toBigInt(orderInfo.amountOut, await tokenOutContract.decimals()), {
          value: this._toBigInt(orderInfo.amountIn, 18),
        });
    } catch (e) {
      console.log('Error placing AVAX-for-token order in OTC3', e);
      throw e;
    }
  }

  async modifyOrder(wallet: Signer, orderId: number, orderInfo: OTC3Order) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const tokenOutContract = new Contract(orderInfo.tokenIn.assetGuid, new utils.Interface(ERC20_ABI), this.provider);

    try {
      await this.otc3
        .connect(wallet)
        .changeOrder(
          orderId,
          orderInfo.tokenOut.assetGuid,
          this._toBigInt(orderInfo.amountOut, await tokenOutContract.decimals())
        );
    } catch (e) {
      console.log('Error modifying order in OTC3', e);
    }
  }

  async dismissOrder(wallet: Signer, orderId: number) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    try {
      const tx = await this.otc3.connect(wallet).dismissOrder(orderId);
      await tx.wait();
    } catch (e) {
      console.log('Error dismissing order from OTC3', e);
      throw e;
    }
  }

  async fulfillOrder(wallet: Signer, orderId: number) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const orderInfo = await this.otc3.orderInfo(orderId);

    const tokenOutContract = new Contract(orderInfo.tokenOut, new utils.Interface(ERC20_ABI), this.provider);
    const tokenOutAllowance = this._toFloat(
      await tokenOutContract.allowance(await wallet.getAddress(), this.otc3.address),
      await tokenOutContract.decimals(),
      6
    );

    if (tokenOutAllowance <= orderInfo.amountIn) {
      await this._setAllowance(tokenOutContract, wallet, this.otc3.address);
    }

    try {
      await this.otc3.connect(wallet).fulfillOrder(orderId);
    } catch (e) {
      console.log('Error fulfilling order in OTC3', e);
      throw e;
    }
  }

  // @dev: only applicable when the order that was placed was token-for-AVAX -meaning the fulfiller will send AVAX.
  async fulfillTokenForAVAXOrder(wallet: Signer, orderId: number, avaxAmount: number) {
    if (!this.otc3) {
      await this.initiateOTC3Connection();
    }

    const orderInfo = await this.otc3.orderInfo(orderId);
    console.log('adsfasdfasdf: ', orderInfo.tokenOut)
    if (orderInfo.tokenOut?.toLowerCase() !== AddressWAVAX) {
      throw Error('Not a token-for-AVAX order');
    }

    try {
      console.log('asdfasdfasdf');
      await this.otc3.connect(wallet).fulfillOrderAVAX(orderId, { value: this._toBigInt(avaxAmount, 18) });
    } catch (e) {
      console.log('Error fulfilling order in OTC3', e);
      throw e;
    }
  }

  private _toBigInt(input: number, decimals: number) {
    return utils.parseUnits(input.toString(), decimals);
  }

  private _toFloat(input: bigint, decimals: number, precision: number) {
    return parseFloat((Number(input) / 10 ** decimals).toFixed(precision));
  }

  private async _setAllowance(token: Contract, wallet: Signer, spender: string) {
    try {
      await token.connect(wallet).approve(spender, constants.MaxUint256);
    } catch (e) {
      console.log('Error setting token allowance:', e);
    }
  }
}
